import React from "react"

import SEO from "../components/seo";
import Layout from "../components/layout"

export default function NotFound() {
  return (
    <Layout>
      <SEO title="404 - Page not Found" /> 
      <section className="page-not-found">
        <div className="container">
          <div className="row">
            <div className="page-not-found-text col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <h1>404</h1>
              <p>
              	<span className="oops">Oops!</span>
              	We can’t seem to find the page you’re looking for.
              </p>
            </div>
            <div className="helpful-links col-lg-9 col-md-8 col-sm-12 col-xs-12">
            	<div className="row">
            		<div className="col-lg-12">
              			<h2>Here are some helpful links instead</h2>
              		</div>
              	</div>
              	<div className="row display-flex">
            		<div className="helpful-link col-lg-4 col-md-4 col-sm-12 col-xs-12">
            			<p>Interested in learning about the Compute Software platform?</p>
            			<a className="btn" href="/platform" role="button">Platform Overview</a>
            		</div>
            		<div className="helpful-link col-lg-4 col-md-4 col-sm-12 col-xs-12">
            			<p>Looking for more technical information?</p>
            			<a className="btn" href="/documentation/overview" role="button">Documentation</a>
            		</div>
            		<div className="helpful-link col-lg-4 col-md-4 col-sm-12 col-xs-12">
            			<p>Have a question or need help?</p>
            			<a className="btn" href="/contact?form=support" role="button">Contact Support</a>
            		</div>
            	</div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
